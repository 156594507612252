/* ServerStatus.css */

/* Main container with opacity background and padding */
.server-status-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 120%;
    max-width: 350px; /* Adjust width as needed */
    padding: 20px;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    border-radius: 10px;
    position: relative;
  }
  
  /* Round logo with beating heart animation */
  .server-logo {
    width: 105%; /* Ensure image fills the container */
    height: 100%;
    border-radius: 50%;
    object-fit: contain; /* Scale the image to fit without clipping */
    position: relative;
    z-index: 20;
  }
  .logo-beat-container {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: visible;
  }
  
  .server-logo2 {
    width: 105%; /* Ensure image fills the container */
    height: 100%;
    object-fit: contain; /* Scale the image to fit without clipping */
    position: relative;
    z-index: 20;
  }
  
  
  /* Beating animation */
  @keyframes beat {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }
  
  .animate-beat {
    animation: beat 1.5s infinite ease-in-out;
  }
  
  /* Particle Glow Effect */
  .particle-glow {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: radial-gradient(circle, #11af1e, transparent);
    animation: particle-glow 2.5s infinite ease-out;
    opacity: 0.7;
    z-index: 1;
  }

  .particle-glow2 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: radial-gradient(circle, #E94E3C, transparent);
    animation: particle-glow 2.5s infinite ease-out;
    opacity: 0.7;
    z-index: 1;
  }
  
  @keyframes particle-glow {
    0% {
      transform: translate(-50%, -50%) scale(0.5);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0;
    }
  }
  
  /* Status information */
  .status-info {
    text-align: right;
  }
  
  /* Font sizes and weights for text */
  h2 {
    font-size: 1.875rem; /* 3xl size */
    font-weight: 800; /* Extra bold font */
  }
  
  span, .text-lg {
    font-weight: 600; /* Semi-bold text */
  }
  
  .status-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
    animation: pulse 2s infinite;
  }

  .status-dot2 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #E94E3C;
    animation: pulse 2s infinite;
  }

  .coming-soon-badge {
    position: absolute;
    top: -32px;  /* Adjust based on your preference */
    right: -14px; /* Adjust based on your preference */
    width: 100px; /* Adjust size */
    height: auto;
    z-index: 10;
    transform: rotate(-11deg); /* Rotate the image by -15 degrees */
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  