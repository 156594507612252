/* src/App.css */
body {
  font-family: Arial, sans-serif;
  background-color: #000;
  color: #fff;
  margin: 0;
  padding: 0;
}

nav {
  background-color: #333;
  padding: 1em;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

nav a {
  color: #fff;
  text-decoration: none;
}

section {
  padding: 2em;
  border-bottom: 1px solid #444;
}

h1, h2 {
  color: #f90;
}

.snow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensure the snow does not interfere with user interactions */
  overflow: hidden;
  z-index: 9999; /* Make sure it's on top */
}

.snowflake {
  position: absolute;
  top: -10px;
  background: rgb(173, 175, 14);
  border-radius: 30%;
  opacity: 0.6;
  pointer-events: none;
  animation: fall linear infinite;
}

/* Falling animation */
@keyframes fall {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh); /* Makes the snowflakes fall down the entire screen */
  }
}

/* Scrollbar container */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: #000000; /* Color of the track */
  border-radius: 10px; /* Optional: rounding the corners */
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #463030; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Optional: rounding the corners */
}

/* Scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: #422c2c; /* Darker color when hovered */
}
