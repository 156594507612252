/* src/components/Navbar.css */
.navbar {
  position: fixed; /* Ensure the navbar is fixed at the top */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  /*  background: url('../../public/bg_5.jpg') no-repeat center center/cover; /* Texture image */
  background: rgba(0, 0, 0, 1); /* Semi-transparent background */
  background-blend-mode: overlay; /* Blend the gradient and image */
  color: white; /* Ensure text is readable */
  border-bottom: 1px solid rgb(153, 155, 25); /* Border at the bottom */
  padding: 1rem 2rem; /* Padding around navbar */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  transition: background 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
}

.navbar.scrolled {
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  background-blend-mode: normal; /* Adjust blend mode if needed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Softer shadow */
  border-bottom: 1px solid rgba(251, 255, 21, 0.459); /* Border at the bottom */
}

  .navbar-container {
    display: flex;
    justify-content: space-between; /* Space out logo and nav links */
    align-items: center;
  }
  
  .logo {
    font-size: 1.5rem;
    color: white;
  }
  
  .nav-links {
    list-style: none; /* Remove bullets */
    display: flex;
    gap: 2rem; /* Space between links */
  }
  
  .nav-links a {
    text-decoration: none; /* Remove underline */
    color: white;
    font-weight: bold; /* Make links bold */
    transition: color 0.3s; /* Smooth color transition */
  }
  
  .nav-links a:hover {
    color: rgba(255, 255, 255, 0.8); /* Change color on hover */
  }

  a:hover span {
    width: 100%;
  }
  
  @media (min-width: 768px) { /* Adjust this media query based on your breakpoint needs */
    .pl-25-percent {
      padding-left: 25%;
    }
  }
  
  /* Inside Navbar.css or any custom CSS file */
@media (max-width: 767px) {
  .navbar-items {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-items {
    display: block;
  }
}

  