/* Scoped CSS for ServerInfo */
.server-info-page .sticky-menu ul {
  padding: 0;
}

.server-info-page .menu-item {
  font-size: 0.875rem;
  padding: 0.25rem 0;
  line-height: 1.2;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.server-info-page .menu-item.highlighted {
  background-color: #f1c40f; /* Gold accent color */
  color: #000; /* Black text for contrast */
  border-radius: 5px; /* Optional: for rounded highlight */
}

.server-info-page .sticky-menu {
  position: sticky;
  top: 100px; /* Adjust based on your desired offset from the top */
  max-height: calc(100vh - 40px); /* Ensures it doesn't exceed viewport height */
  overflow-y: auto; /* Adds scroll inside the menu if it grows too tall */
}

.server-info-page .section-offset {
  padding: 1rem;
}

.server-info-page h2 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.server-info-page p, .server-info-page ul {
  font-size: 0.875rem;
  line-height: 1.4;
  margin-bottom: 0.75rem;
}

.server-info-page ul li {
  margin-bottom: 0.5rem;
}

.server-info-page .bg-black {
  background-color: rgba(0, 0, 0, 0.85);
}

.server-info-page .text-white {
  color: #e0e0e0;
}

  /* Inside Navbar.css or any custom CSS file */
  @media (max-width: 767px) {
    .sidebar-items {
      display: none;
    }
  }
  
  @media (min-width: 768px) {
    .sidebar-items {
      display: block;
    }
  }